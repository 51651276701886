.my-account-auth-user-panel-v1 .shortcuts li a {
  padding: 29px 5px 0 5px !important;
}

.my-account-auth-user-panel-v1 .shortcuts li a.account {
  font-size: 14px !important;
  line-height: 18px !important;
  padding: 23px 27px !important;
}

.my-account-auth-user-panel-v1 .header h2 {
  font-size: 26px !important;
  margin: 0 0 5px 0 !important;
}
